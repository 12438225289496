import imgMobileAvailabilities from '@assets/images/mobile-availabilities.png'
import imgMobileExchange from '@assets/images/mobile-exchange.png'
import imgMobileNotifications from '@assets/images/mobile-notifications.png'
import imgMobilePlanning from '@assets/images/mobile-planning.png'

const content = [
    {
        title: 'Accès et consultation de son propre planning',
        description: "Consultez vos horaires via l'application mobile Staffea, où et quand vous voulez.",
        image: imgMobilePlanning,
        alt: 'Staffea, application mobile consultation du planning',
    },
    {
        title: 'Partage de ses disponibilités',
        description: 'Partagez vos disponibilités sur vos jours de repos pour simplifier les remplacements.',
        image: imgMobileAvailabilities,
        alt: 'Staffea, application mobile affichage des disponibilités',
    },
    {
        title: 'Échange de jours entre collaborateurs',
        description: 'Échangez votre planning avec un de vos collègues en cas de besoin.',
        image: imgMobileExchange,
        alt: 'Staffea, application mobile échange de jours entre collaborateurs',
    },
    {
        title: 'Notifications push immédiates',
        description: 'Soyez notifié en temps réel après chaque changement de planning.',
        image: imgMobileNotifications,
        alt: 'Staffea application mobile affichage notification push',
    },
]

export default content
