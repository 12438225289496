import contractIcon from '@assets/svg/picto-contract.svg'
import legalIcon from '@assets/svg/picto-legal-constraints.svg'
import paymentIcon from '@assets/svg/picto-payment.svg'
import planningIcon from '@assets/svg/picto-planning.svg'
import replacementIcon from '@assets/svg/picto-replacement.svg'
import transfertIcon from '@assets/svg/picto-transfert.svg'

const functionalities = [
    {
        id: 1,
        media: planningIcon,
        title: 'Création des plannings en 2 heures',
        content:
            'Ludique et ergonomique, notre plateforme vous permet de créer vos plannings en moins de 2H grâce à la génération automatique des roulements.',
    },
    {
        id: 2,
        media: legalIcon,
        title: 'Intégration de vos contraintes réglementaires',
        content:
            'Staffea répond à vos exigences réglementaires. En cas de non-conformité à la durée légale du travail de votre planning, vous recevez une alerte pour le modifier au plus vite.',
    },
    {
        id: 3,
        media: paymentIcon,
        title: 'Transmission des éléments de pré-paie à votre logiciel',
        content:
            "Notre logiciel de planning professionnel s'interface à votre logiciel de paie pour plus de facilité. Il calcule vos éléments variables de paie automatiquement.",
    },
    {
        id: 4,
        media: replacementIcon,
        title: 'Traitement des demandes de remplacement en 5 minutes',
        content:
            "Votre personnel peut indiquer ses disponibilités et être alerté en temps réel en cas de créneau à pourvoir, et ce depuis l'application mobile.",
    },
    {
        id: 5,
        media: transfertIcon,
        title: "Bourse d'échanges d'horaires entre collaborateurs",
        content:
            "Les remplacements n'ont jamais été aussi faciles à intégrer. Vos collaborateurs échangent des horaires de travail via l'application. Vous n'avez plus qu'à valider les demandes.",
    },
    {
        id: 6,
        media: contractIcon,
        title: 'Génération automatique des contrats de travail',
        content:
            "Plus qu'un outil de planning, notre logiciel intelligent génère automatiquement vos contrats de travail. Dématérialisez les démarches de recrutement grâce à la signature électronique.",
    },
]

export default functionalities
