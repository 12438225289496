const specificities = [
    {
        id: 1,
        color: 'var(--chakra-colors-green-500)',
        name: 'Santé',
        description:
            "Optimisez la qualité de vie au travail de votre personnel de santé en vous appuyant sur Staffea. Grâce à l'intelligence artificielle, les échanges d'horaires, congés et arrêts maladies n'ont jamais été aussi faciles à intégrer dans un planning !",
        fields: ['Ehpad', 'hôpitaux', 'cliniques', 'soins à domicile', 'laboratoire'],
    },
    {
        id: 2,
        color: 'var(--chakra-colors-yellow)',
        name: 'Services',
        description:
            "Gérez au mieux les plannings de vos employés et leurs contrats de travail grâce à notre logiciel de gestion de planning du personnel. Un gain de temps pour vous et pour eux ! Démarquez-vous sur le marché de l'emploi et attirez les nouvelles générations de travailleurs.",
        fields: ['Restauration', 'hôtellerie', 'distribution', 'sécurité', "centres d'appel"],
    },
    {
        id: 3,
        color: 'var(--chakra-colors-orange)',
        name: 'Industrie',
        description:
            "Facilitez votre gestion RH avec le logiciel de gestion de planning Staffea, adapté aux besoins de l'industrie. Concevez des horaires types pour vos ouvriers selon vos besoins de production, et intégrez-les automatiquement dans vos plannings de chantier.",
        fields: ['Production industrielle', 'logistique', 'génie civil'],
    },
]

export default specificities
