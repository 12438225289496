import * as React from 'react'

import imgDesktopPlanning from '@assets/images/desktop-planning.png'
import imgMobilePlanning from '@assets/images/mobile-planning.png'
import gradients from '@assets/svg/gradients-center.svg'
import { Box, Center, Flex, HStack, Heading, Icon, Image, Text, useMediaQuery } from '@chakra-ui/react'
import Seo from '@components/Seo'
import LogoIcon from '@components/core/LogoIcon'
import Section from '@components/core/Section'
import { CtaButton } from '@components/ui/Button'
import { Card, FlippingCard, GradientCard, HorizontalGridCard } from '@components/ui/Card'
import { HorizontalCarousel, VerticalCarousel } from '@components/ui/Carousel'
import { MobileDesktopDisplay } from '@components/ui/Display'
import Flow from '@components/ui/Flow'
import IconItemList from '@components/ui/IconItemList'
import IconLink from '@components/ui/IconLink'
import MenuSticky from '@components/ui/MenuSticky'
import Quote from '@components/ui/Quote'
import {
    features,
    functionalities,
    horizontalCarouselContent,
    mission,
    specificities,
    verticalCarouselContent,
} from '@data/home'
import { CALENDLY_URL } from '@helpers/url'
import { graphql } from 'gatsby'
import { AiOutlineArrowDown } from 'react-icons/ai'
import { FaLongArrowAltRight } from 'react-icons/fa'
import { GrCheckmark } from 'react-icons/gr'
import { helmetJsonLdProp } from 'react-schemaorg'
import { Thing, WebSite } from 'schema-dts'
import { v4 as uuidv4 } from 'uuid'

const CARD_MIN_HEIGHT = '425px'

const jsonLd = helmetJsonLdProp<Thing>({
    '@context': 'https://schema.org',
    '@id': 'https://www.staffea.com',
    '@type': 'Organization',
    name: 'Staffea',
    url: 'https://www.staffea.com',
    address: {
        '@type': 'PostalAddress',
        addressLocality: 'Colombelles',
        postalCode: '14460',
        streetAddress: '8 rue Léopold Sédar-Senghor',
        addressCountry: {
            '@type': 'Country',
            name: 'FR',
        },
    },
    contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'customer service',
        availableLanguage: 'fr',
        email: 'contact@staffea.com',
    },
})

const jsonLdWebsite = helmetJsonLdProp<WebSite>({
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: 'https://www.staffea.com',
    name: 'Staffea',
})

const HomePage = () => {
    const [isTablet, isDesktop] = useMediaQuery(['(min-width: 1200px)', '(min-width: 1600px)'])

    return (
        <>
            <Seo
                title="Logiciel Gestion de Planning"
                description="Découvrez Staffea pour la gestion de vos plannings ➜ ✓ Installation rapide ✓ Création en 2 heures ✓ Gestion en temps réel ➜ Demandez une démo !"
                script={[jsonLd, jsonLdWebsite]}
            />

            <main>
                <MenuSticky>
                    <Section paddingY={10} bg="indigo.100" id="staffea-section" name="staffea">
                        <Flex
                            pt={14}
                            pb={20}
                            gap={8}
                            align="center"
                            justify={['center', 'center', 'space-between']}
                            wrap="wrap"
                            maxW="1200px"
                            mx="auto"
                        >
                            <Flex
                                direction="column"
                                align="center"
                                mx="auto"
                                sx={{
                                    '@media (min-width: 1440px)': {
                                        maxW: '50%',
                                        marginX: 'revert',
                                        alignItems: 'start',
                                    },
                                }}
                            >
                                <Heading
                                    as="h1"
                                    mb={10}
                                    fontWeight="bold"
                                    color="indigo.500"
                                    textAlign={['center', 'center', 'start']}
                                >
                                    Logiciel de gestion de planning
                                </Heading>

                                <IconItemList list={features} icon={GrCheckmark} align="start" />

                                <Flex direction="column" w={['100%', '100%', '50%']} pt="12">
                                    <a href={CALENDLY_URL} target="_blank" rel="noreferrer">
                                        <CtaButton
                                            text="Demander une démo"
                                            description="Démonstration de 30 minutes en visioconférence"
                                        />
                                    </a>
                                </Flex>
                            </Flex>

                            <Box
                                mx="auto"
                                sx={{
                                    '@media (min-width: 1440px)': {
                                        marginX: 'revert',
                                    },
                                }}
                            >
                                <MobileDesktopDisplay
                                    mobileContent={{
                                        image: (
                                            <Image
                                                src={imgMobilePlanning}
                                                alt="Staffea, application mobile écran de consultation du planning individuel"
                                            />
                                        ),
                                        description: 'A chaque salarié, son planning individuel !',
                                    }}
                                    desktopContent={{
                                        image: (
                                            <Image
                                                src={imgDesktopPlanning}
                                                alt="Staffea, application web écran de gestion du planning des managers"
                                            />
                                        ),
                                        description: 'Votre interface de gestion des plannings.',
                                    }}
                                />
                            </Box>
                        </Flex>
                    </Section>

                    <Section bg="white" id="interoperability-section" name="interopérabilité">
                        <Box py={20}>
                            <GradientCard maxW="1050px" mb="16">
                                <Flex direction="column" alignItems="center" justifyContent="center" color="white">
                                    <Text as="h2" fontSize="lg" fontWeight="bold" align="center" mb={6}>
                                        Avec Staffea, pas d&#39;installation informatique complexe à prévoir.
                                        <br /> Notre solution se connecte à tous les logiciels RH !
                                    </Text>

                                    <Text fontSize="sm" align="center" px={[0, 0, 20]} mb={8}>
                                        Un ordinateur et un accès internet : c&#39;est tout ce dont vous avez besoin
                                        pour utiliser le logiciel de planning RH Staffea. Il se connecte aux solutions
                                        déjà en place dans votre entreprise : logiciels métiers et solutions RH. Le
                                        déploiement de notre logiciel de planning professionnel est effectué à distance,
                                        en intégrant automatiquement vos dossiers administratifs et vos conventions
                                        collectives. Il est garanti en moins de 48H, pour une mise en œuvre rapide.
                                    </Text>

                                    <IconLink
                                        text="Voir le process d'intégration"
                                        to="#onboarding-section"
                                        icon={AiOutlineArrowDown}
                                        color="white"
                                        hoverColor="green.500"
                                    />
                                </Flex>
                            </GradientCard>
                        </Box>
                    </Section>

                    <Section bg="white" id="jobs-section" name="métiers" mb={24}>
                        <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500" mb={4} mt={8}>
                            Une solution de planning
                            <Text as="span" color="green.500" display="block">
                                qui s&#39;adapte aux spécificités de votre métier.
                            </Text>
                        </Text>

                        <Text color="indigo.500" fontSize={['xs', 'sm']} mb={10}>
                            Peu importe votre activité, notre logiciel de planning d&#39;entreprise s&#39;y adapte.
                            Staffea offre de multiples fonctionnalités utiles à votre métier, ainsi qu&#39;une grande
                            flexibilité.
                        </Text>

                        <Flex
                            columnGap={6}
                            rowGap={12}
                            wrap="wrap"
                            justifyItems="stretch"
                            margin="auto"
                            width="100%"
                            display="grid"
                            gridAutoFlow={isTablet ? 'column dense' : 'row dense'}
                        >
                            {specificities.map(({ id, color, name, description, fields }) => (
                                <FlippingCard
                                    key={id}
                                    front={
                                        <Card size="auto" p="8" minH={CARD_MIN_HEIGHT}>
                                            <LogoIcon color={color} mb="3" />

                                            <Text as="h3" fontWeight="bold" color={color}>
                                                {`Staffea ${name}`}
                                            </Text>

                                            <Text fontWeight="bold" color="indigo.500" mb={4}>
                                                {fields.join(', ')}.
                                            </Text>

                                            <Text fontSize="sm" color="indigo.500">
                                                {description}
                                            </Text>
                                        </Card>
                                    }
                                    back={
                                        <Card size="auto" p="8" bg={color} minH={CARD_MIN_HEIGHT}>
                                            <Flex direction="column" justify="space-around" minH="315px">
                                                <Text color="white" fontWeight="bold" align="center">
                                                    STAFFEA POUR VOUS
                                                </Text>

                                                <Box color="white" textAlign="center">
                                                    <Text variant="inline">Staffea&nbsp;</Text>

                                                    <Text variant="bold">
                                                        réduit drastiquement le temps de gestion des plannings
                                                    </Text>

                                                    <Text variant="inline">
                                                        &nbsp;de vos équipes. Découvrez comment notre outil s&#39;adapte
                                                        à vos spécificités&nbsp;
                                                    </Text>

                                                    <HStack justify="center">
                                                        <Text variant="bold" textTransform="lowercase">
                                                            {name}
                                                        </Text>

                                                        <Icon as={AiOutlineArrowDown} color="white" w="5" h="5" />
                                                    </HStack>
                                                </Box>
                                            </Flex>

                                            <a href={CALENDLY_URL} target="_blank" rel="noreferrer">
                                                <CtaButton
                                                    text="Demander une démo !"
                                                    color="white"
                                                    textColor={color}
                                                    hoverColor="indigo.500"
                                                />
                                            </a>
                                        </Card>
                                    }
                                />
                            ))}
                        </Flex>
                    </Section>

                    <Section bg="indigo.100" id="mobile-app-section" name="app mobile">
                        <Box py={20}>
                            <Center mb={6}>
                                <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500" mb={4}>
                                    Une application mobile dédiée aux collaborateurs
                                    <Text as="span" color="green.500" display="block">
                                        pour gagner en réactivité.
                                    </Text>
                                </Text>
                            </Center>

                            <VerticalCarousel
                                contents={verticalCarouselContent}
                                footer={
                                    <a href={CALENDLY_URL} target="_blank" rel="noreferrer">
                                        <CtaButton
                                            text="Demander une démo"
                                            description="Démonstration de 30 minutes en visioconférence"
                                            height="14"
                                        />
                                    </a>
                                }
                            />
                        </Box>
                    </Section>

                    <Section bg="white" id="features-section" name="fonctionnalités">
                        <Box py={20}>
                            <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500">
                                Des fonctionnalités de planning précieuses
                                <Text as="span" color="green.500" display="block">
                                    pour économiser du temps.
                                </Text>
                            </Text>

                            <Flex my="12" gap="8" wrap="wrap" mx="auto">
                                {functionalities.map(({ id, media, title, content }) => (
                                    <HorizontalGridCard
                                        minH="170"
                                        key={id}
                                        media={media}
                                        title={title}
                                        content={content}
                                        maxW="485px"
                                        imagePadding={isDesktop ? '25' : '5'}
                                    />
                                ))}
                            </Flex>

                            <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500" py={10}>
                                Les points forts de Staffea
                                <Text as="span" color="green.500" display="block">
                                    pour séréniser la gestion de planning.
                                </Text>
                            </Text>

                            <HorizontalCarousel
                                content={horizontalCarouselContent.map(({ title, description, image, alt }) => {
                                    return {
                                        title,
                                        description: (
                                            <>
                                                <Text color="indigo.500" fontSize="xs" align="start">
                                                    {description}
                                                </Text>
                                            </>
                                        ),
                                        image: <Image key={uuidv4()} src={image} alt={alt} objectFit="scale-down" />,
                                    }
                                })}
                            />
                        </Box>
                    </Section>

                    <Section
                        id="onboarding-section"
                        name="onboarding"
                        backgroundImage={gradients}
                        footer={
                            <Section bg="indigo.500">
                                <Flex
                                    w="fit-content"
                                    mx="auto"
                                    py={35}
                                    gap={6}
                                    align="center"
                                    direction={['column', 'column', 'row']}
                                >
                                    <Text as="span" variant="inline" color="white" fontSize="sm" align="center">
                                        Staffea réduit <Text variant="bold">drastiquement</Text> le temps de saisie et
                                        de modification des plannings de vos équipes.
                                    </Text>

                                    <Icon
                                        as={FaLongArrowAltRight}
                                        color="white"
                                        w="6"
                                        h="6"
                                        display={['none', 'none', 'block']}
                                    />

                                    <a href={CALENDLY_URL} target="_blank" rel="noreferrer">
                                        <CtaButton width="200px" hoverColor="green.500" text="Réserver une démo !" />
                                    </a>
                                </Flex>
                            </Section>
                        }
                    >
                        <Box py={20}>
                            <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500" mb={4}>
                                Un accompagnement technique
                                <Text as="span" color="green.500" display="block">
                                    pour vous aider à intégrer Staffea à vos process.
                                </Text>
                            </Text>

                            <Flow>
                                <Card size="xs" align="center">
                                    <Text
                                        as="h3"
                                        color="indigo.500"
                                        fontWeight="bold"
                                        mb="4"
                                        fontSize={isTablet && !isDesktop ? 'sm' : 'md'}
                                    >
                                        Récupération de vos données
                                    </Text>

                                    <Text color="indigo.500" mb="4" fontSize={isTablet && !isDesktop ? 'xs' : 'md'}>
                                        Intégrez vos données existantes à Staffea, simplement en téléchargeant un
                                        fichier d&#39;import. Nous ajoutons automatiquement vos conventions collectives
                                        et vos dossiers administratifs lors du déploiement.
                                    </Text>
                                </Card>

                                <Card size="xs" align="center">
                                    <Text
                                        as="h3"
                                        color="indigo.500"
                                        fontWeight="bold"
                                        mb="4"
                                        fontSize={isTablet && !isDesktop ? 'sm' : 'md'}
                                    >
                                        Configuration du planning
                                    </Text>

                                    <Text color="indigo.500" mb="4" fontSize={isTablet && !isDesktop ? 'xs' : 'md'}>
                                        Configurez vos plannings en 2H seulement. Créez des roulements types et faites
                                        confiance à la suggestion automatique de notre logiciel de gestion de planning
                                        du personnel. Si votre planning n&#39;est pas conforme, Staffea le détecte et
                                        vous en informe immédiatement.
                                    </Text>
                                </Card>

                                <Card size="xs" align="center">
                                    <Text
                                        as="h3"
                                        color="indigo.500"
                                        fontWeight="bold"
                                        mb="4"
                                        fontSize={isTablet && !isDesktop ? 'sm' : 'md'}
                                    >
                                        Formation au logiciel
                                    </Text>

                                    <Text color="indigo.500" mb="4" fontSize={isTablet && !isDesktop ? 'xs' : 'md'}>
                                        Nous fournissons 2 jours de formation à distance à vos plannings managers pour
                                        leur apprendre à modifier les plannings en toute autonomie. Vous bénéficiez
                                        d&#39;une aide en ligne et d&#39;un support utilisateur à tout instant.
                                    </Text>
                                </Card>

                                <Card size="xs" align="center">
                                    <Text
                                        as="h3"
                                        color="indigo.500"
                                        fontWeight="bold"
                                        mb="4"
                                        fontSize={isTablet && !isDesktop ? 'sm' : 'md'}
                                    >
                                        Mise en service
                                    </Text>

                                    <Text color="indigo.500" mb="4" fontSize={isTablet && !isDesktop ? 'xs' : 'md'}>
                                        Le déploiement de Staffea est effectué à distance : aucune installation
                                        n&#39;est nécessaire. Notre outil est accessible avec un simple accès internet !
                                        L&#39;installation de votre nouveau logiciel de planification est garantie en
                                        moins de 48H.
                                    </Text>
                                </Card>
                            </Flow>
                        </Box>
                    </Section>

                    <Section bg="indigo.100" id="mission-section" name="mission">
                        <Box py={20}>
                            <Center textAlign="center">
                                <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500" mb={10}>
                                    Plus qu&#39;un simple logiciel de planning,
                                    <Text as="span" color="green.500" display="block">
                                        Staffea prend soin de vos équipes&nbsp;!
                                    </Text>
                                </Text>
                            </Center>

                            <Quote nbCol={2} content={mission?.content} author={mission?.author} />
                        </Box>
                    </Section>
                </MenuSticky>
            </main>
        </>
    )
}

export default HomePage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["homepage"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
